<template>
  <v-card elevation="0">
    <pre>
<v-tabs v-model="currentTab" fixed-tabs>
  <v-tab v-for="tab in tabs" :key="tab.key" :href="'#tab-' + tab.key">
    {{ tab.name }}
  </v-tab>
</v-tabs>
<v-tabs-items v-model="currentTab">
  <v-tab-item value="tab-backend">
    <div class="tab-item-wrapper">
      <v-layout align-center justify-center column fill-height>
        <div class="iframe-wrapper">
          <iframe :src="backendLicenses" class="iframe" />
        </div>
      </v-layout>
    </div>
  </v-tab-item>
  <v-tab-item value="tab-frontend">
    <div class="tab-item-wrapper">
      <v-layout align-center justify-center column fill-height>
        <div class="iframe-wrapper">
          <iframe src="licenses.txt" class="iframe" />
        </div>
      </v-layout>
    </div>
  </v-tab-item>
</v-tabs-items>

</pre>
  </v-card>
</template>

<script>
import Vue from 'vue';
export default {
  components: {},
  data: function() {
    return {
      currentTab: 'tab-backend',
      loading: false
    };
  },

  computed: {
    backendLicenses() {
      return new URL('static/licenses.txt', Vue.axios.defaults.baseURL);
    },
    tabs() {
      return [
        { key: 'backend', name: this.$t('licenses.backend') },
        { key: 'frontend', name: this.$t('licenses.frontend') }
      ];
    }
  }
};
</script>

<style lang="sass">

.iframe-wrapper
   height: 100%
   width: 80%
   min-height: 50vh
   margin: 10px
   display: flex
   flex-direction: column
   overflow: hidden
   align-items: center

.iframe
  flex-grow: 1
  min-width: 90%
  border: none
  margin: 0
  padding: 0
</style>
